<template>
    <div class="favSongs-container">
        <div class="top"></div>
        <div class="content">
            <Header :title="'我的收藏'" :bgColor="'#00000000'" :leftArrowType="1" :titleColor="'#ffffff'" :leftClick="goBack">
            </Header>
            <div style="display: flex;width: 100%;padding: 00px 20px 20px 20px;box-sizing: border-box;align-items: center;">
                <img src="../../assets/app/music_fav_icon.png" style="width: 72px;height: auto;object-fit: cover;">
                <p style="color: white;font-size: 16px;margin-left: 15px;">共{{ dataList.length }}个内容</p>
            </div>
            <div
                style="flex: 1;border-top-right-radius: 16px;border-top-left-radius: 16px;background-color: white;padding: 10px 20px;box-sizing: border-box;overflow-y: auto;">
                <div v-if="dataList.length > 0">
                    <div v-for="(song, index) in dataList" :key="index" class="song-item" @click="gotoPaly(index)">
                        <div style="flex: 1;">
                            <p style="color: gray;font-size: 12px;">{{ song.song_name }}</p>
                            <p style="color: #333333;font-size: 14px;margin-top: 10px;">{{ song.from_album_name }}</p>
                        </div>
                        <img src="../../assets/app/icon_right_arrow.png"
                            style="width: 12px;height: 18px;object-fit: cover;">

                    </div>
                </div>
                <el-empty v-else></el-empty>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../../components/app/Header.vue'
import {
    getFavoriteSongsV1
} from '../../api/app'
import {
    getAppKidId,
    getAppUserId
} from '../../utils/store'
import { Loading } from "element-ui"
import Vue from "vue"
export default ({
    name: 'index',
    components: {
        Header,
    },
    data() {
        return {
            dataList: []
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {


        gotoPaly(index){
            Vue.prototype.$songList = this.dataList
            this.$router.push({
                path: '/app-audio',
                query: {
                    index: index
                }
            })
        },

        fetchData() {

            let loadingInstance = Loading.service({
                fullscreen: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.8)",
            });

            getFavoriteSongsV1(getAppUserId(),getAppKidId()).then((res) => {
                loadingInstance.close()
                let result = res.data
                if (result.code == 0) {
                    this.dataList = result.data
                }
            })
        },
        goBack() {
            this.$router.go(-1)
        }
    }
})
</script>

<style lang="less" scoped>
.favSongs-container {
    width: 100vw;
    height: 100vh;
    position: relative;

    .top {
        position: absolute;
        width: 100%;
        height: 200px;
        background-image: url('../../assets/app/bg_collect_fav.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 100%;
    }

    .content {
        position: absolute;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    .song-item {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        padding: 15px 0px;
        box-sizing: border-box;
        border-bottom: 1px solid #EEEEEE;
    }

}
</style>